import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CHAT_HISTORY } from "../utils/ApiEndpoints";
// import axios from "axios";
import yojn from "yojn-cli";
export const getChatHistory = createAsyncThunk(
  "getChatHistory",
  async (payload) => {
    // console.log("historyPayload", payload);
    try {
      const response = await yojn.history(CHAT_HISTORY, payload);
      if (response) {
        // console.log("history", response);
        return response;
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }
);

const HistorySlice = createSlice({
  name: "historyData",
  initialState: {
    historyData: [],
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatHistory.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(getChatHistory.fulfilled, (state, { payload }) => {
        state.status = "Successed";
        state.historyData = payload;
      })
      .addCase(getChatHistory.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});
export default HistorySlice.reducer;

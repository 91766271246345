import { configureStore } from "@reduxjs/toolkit";
import RunyojnSliceReducer from "../Slices/RunyojnSlice";
import HistorySliceReducer from "../Slices/HistorySlice";
import InitialiseYojnSlice from "../Slices/InitialiseYojnSlice";
import UserConditonsSlice from "../Slices/UserConditonsSlice";
export const store = configureStore({
  reducer: {
    messages: RunyojnSliceReducer,
    historyData: HistorySliceReducer,
    initData: InitialiseYojnSlice,
    userConditions: UserConditonsSlice,
  },
});

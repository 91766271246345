import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { INIT_USER } from "../utils/ApiEndpoints";
import yojn from "yojn-cli";
export const getInitialseAgent = createAsyncThunk(
  "getInitialseAgent",
  async (payload) => {
    // console.log("initPayload", payload);
    // console.log("INIT_USER", INIT_USER);
    try {
      const res = await yojn.init(INIT_USER, payload);
      if (res) {
        console.log(res.YojnResponse);
      }
    } catch (error) {
      console.log("Error", error);
    }
  }
);

const InitializeYojnSlice = createSlice({
  name: "initData",
  initialState: {
    initData: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitialseAgent.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(getInitialseAgent.fulfilled, (state) => {
        state.status = "Successed";
      })
      .addCase(getInitialseAgent.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export default InitializeYojnSlice.reducer;

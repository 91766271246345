import "./App.css";
import React from "react";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthGuard from "./authguard";
import NotFound from "./Pages/NotFound";
// import LoginUser from "./Pages/LoginUser";
const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="ibgpt" element={<AuthGuard />}>
          <Route path="" element={<Navigate to="home" />} />
          <Route path="home" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;

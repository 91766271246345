// import { useGoogleLogin } from "@react-oauth/google";
import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

// export const googleLoginAuth = createAsyncThunk("googleLoginAuth", async () => {
//   try {
//     useGoogleLogin({
//       onSuccess: async (tokenResponse) => {
//         const res = await axios.get(
//           "https://www.googleapis.com/oauth2/v3/userinfo",
//           {
//             headers: {
//               Authorization: `Bearer ${tokenResponse.access_token}`,
//             },
//           }
//         );
//         if (res.status === 200) {
//           console.log("res||", res);
//           localStorage.setItem(
//             "userDetails",
//             JSON.stringify({
//               ...res.data,
//             })
//           );
//           return res.data;
//         }
//       },
//     });
//   } catch (error) {
//     console.log("Error", error);
//   }
// });

const UserConditionsSlice = createSlice({
  name: "userConditons",
  initialState: {
    userDetails: null,
    googleLogin: false,
    userID: "",
    expanded: false,
    consentValue: 1,
    status: "idle",
    error: null,
  },
  reducers: {
    getUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    changeGoogleLoginConditon: (state, { payload }) => {
      // console.log("loginPayload", payload);
      state.googleLogin = payload;
    },
    changeExpanded: (state, { payload }) => {
      // console.log("changeexpanded", payload);
      state.expanded = payload;
    },
    changeConsentValue: (state) => {
      state.consentValue = 1 - state.consentValue;
    },
  },
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(googleLoginAuth.pending, (state) => {
  //         state.status = "Pending";
  //       })
  //       .addCase(googleLoginAuth.fulfilled, (state, { payload }) => {
  //         state.status = "Successed";
  //         state.userDetails = payload;
  //       })
  //       .addCase(googleLoginAuth.rejected, (state, action) => {
  //         state.status = "Failed";
  //         state.error = action.error.message;
  //       });
  //   },
});
export const {
  getUserDetails,
  changeGoogleLoginConditon,
  changeExpanded,
  changeConsentValue,
} = UserConditionsSlice.actions;
export default UserConditionsSlice.reducer;

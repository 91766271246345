import "./Home.css";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import { useEffect, useState } from "react";
import { uuid } from "../uuid";
import { getChatHistory } from "../Slices/HistorySlice";
import { useDispatch } from "react-redux";
import { getInitialseAgent } from "../Slices/InitialiseYojnSlice";

const Home = () => {
  const userParseDetails = localStorage.getItem("userInfo");
  const userDetails = JSON.parse(userParseDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getInitialseAgent({
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          userId: userDetails?.email || "No User Initialise",
          orgId: "diaratech",
          authKey: process.env.REACT_APP_AUTHKEY,
          app: "masterApp",
        }),
      })
    );
  }, [dispatch, userDetails?.email, userDetails.name]);
  useEffect(() => {
    dispatch(
      getChatHistory({
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          orgId: "diaratech",
          userId: userDetails?.email,
        }),
      })
    );
  }, [dispatch, userDetails?.email]);
  const [uid, setUid] = useState("");
  useEffect(() => {
    setUid(uuid());
  }, []);
  // console.log("uid>>", uid);
  return (
    <div className="home">
      <Main uid={uid} setUid={setUid} />
      <Sidebar uid={uid} setUid={setUid} />
    </div>
  );
};

export default Home;

import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// import { Route } from "react-router-dom";

const AuthGuard = () => {
  const savedUserDetails = localStorage.getItem("userInfo");
  const userDetails = JSON.parse(savedUserDetails);
  const navigate = useNavigate();
  // const { Component } = props;
  useEffect(() => {
    if (!userDetails) {
      navigate("/");
    }
  });
  return (
    // <div className="App">
    <Outlet />
    // </div>
  );
};

export default AuthGuard;

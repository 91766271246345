// NotFound.js
import "./NotFound.css";
import React from "react";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="moon"></div>
      <div className="moon-crater"></div>
      <h1>404</h1>
      <p>Not Found</p>
    </div>
  );
};

export default NotFound;

import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
// import IBGPT from "../ChatGPT_Clone_assets/assets/IBGPT Logo gradient without bg.png";
import whiteIBGPT from "../ChatGPT_Clone_assets/assets/IBGPT Logo Black with white bg.svg";

import { IoMdSettings } from "react-icons/io";
// import { useUserContext } from "../context/ContextApi";
import { FormControlLabel, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editMessagesByHistory } from "../Slices/RunyojnSlice";
import {
  changeConsentValue,
  changeExpanded,
} from "../Slices/UserConditonsSlice";
import { uuid } from "../uuid";
const Sidebar = ({ setUid }) => {
  const [visibleListItems, setVisibleListItems] = useState(false);
  const [historyIndex, setHistoryIndex] = useState(null);
  const [uniqueHistoryData, setUniqueHistoryData] = useState([]);
  // const [latestanswers, setLatestAnswers] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { historyData } = useSelector((state) => state?.historyData);
  console.log("history", historyData);
  useEffect(() => {
    if (historyData) {
      setUniqueHistoryData(
        Array?.from(
          historyData
            ?.reduce((map, obj) => {
              if (!map.has(obj.groupChatId)) {
                map.set(obj.groupChatId, obj);
              }
              return map;
            }, new Map())
            ?.values()
        )
      );
    }
  }, [historyData]);

  // console.log("HIstory||", uniqueHistoryData);
  const { expanded, consentValue } = useSelector(
    (state) => state?.userConditions
  );
  // const reverseHistory = historyData?.slice().reverse();
  // console.log(_.uniq(historyData))
  // console.log("latest", latestanswers);
  const handleHistoryClicked = (t, index) => {
    const answerChat = historyData?.filter(
      (data) => data.groupChatId === t.groupChatId
    );
    const newAnswers = answerChat
      ?.map((ans) => [
        { text: ans.input, isBot: false },
        {
          text: ans.output || "No output",
          isBot: true,
          conversationId: ans.conversationId,
        },
      ])
      .flat();
    // console.log("newanswers", newAnswers);
    if (answerChat) {
      dispatch(editMessagesByHistory(newAnswers));
      // setLatestAnswers([]);
    }
    setUid(t.groupChatId);
    setHistoryIndex(index);
  };

  const handleNewChat = () => {
    dispatch(
      editMessagesByHistory([
        {
          text: "How can i help you today?",
          isBot: true,
        },
      ])
    );
    setUid(uuid());
    setHistoryIndex(null);
  };

  return (
    <div className={`sideBar ${expanded ? "closeSidebar" : "openSidebar"}`}>
      <div className="upperSide">
        <div className="upperSideTop" onClick={() => handleNewChat()}>
          <img src={whiteIBGPT} alt="Logo" />
          <span> New Chat</span>
          <FaRegEdit />
        </div>
        <div className="chathistory">
          {uniqueHistoryData
            ?.slice()
            ?.reverse()
            ?.map((t, index) => (
              // console.log("index", index);
              // if (t.output === null) {
              //   return (
              <div
                onClick={() => handleHistoryClicked(t, index)}
                key={index}
                className={`historyfield ${
                  index === historyIndex && "firsthistory"
                }`}
              >
                <p>{t.input}</p>
              </div>
              // );
              // }
              // return null;
            ))}
        </div>
      </div>
      <div className="lowerSide">
        <div className="childLowerside">
          {visibleListItems && (
            <div className="settingItems">
              <div className="settingListItems">
                Consent
                <div className="toggle">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={consentValue === 1}
                        onChange={() => dispatch(changeConsentValue())}
                      />
                    }
                  />
                </div>
              </div>
              <div className="settingListItems">
                <AiOutlineDelete size={"2rem"} className="settingIcon" />
                Delete Account
              </div>
              <div className="settingListItems">
                <MdOutlinePrivacyTip size={"2rem"} className="settingIcon" />
                Privacy Policy
              </div>
              <div className="divider"></div>
              <div
                className="settingListItems"
                onClick={() => {
                  localStorage.removeItem("userInfo");
                  navigate("/");
                }}
              >
                <IoLogOutOutline size={"2.3rem"} className="settingIcon" />
                Logout
              </div>
            </div>
          )}
          <div
            className="setting"
            onClick={() => setVisibleListItems(!visibleListItems)}
          >
            <IoMdSettings size={"2rem"} className="settingIcon" />
            Settings
          </div>
        </div>
      </div>
      <div
        className="closesidebarbutton"
        onClick={() => dispatch(changeExpanded(!expanded))}
      >
        <h3>X</h3>
      </div>
    </div>
  );
};

export default Sidebar;

const BASE_URL = process.env.REACT_APP_BASE_URL;
// console.log("base", BASE_URL);
//userinitalize
export const INIT_USER = `${BASE_URL}initializeYojn`;

//Runyojn
export const RUN_YOJN = `${BASE_URL}/runYojn`;

//chathistory
export const CHAT_HISTORY = `${BASE_URL}/userHistory`;
export const USER_FEEDBACK = `${BASE_URL}/updateUserFeedback`;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
import { getChatHistory } from "./HistorySlice";
import { RUN_YOJN } from "../utils/ApiEndpoints";
import yojn from "yojn-cli";

export const getRunyojn = createAsyncThunk("getRunyojn", async (payload2) => {
  const userParseDetails = localStorage.getItem("userInfo");
  const userDetails = JSON.parse(userParseDetails);
  // console.log("payload22||", payload2);
  const { payload, dispatch } = payload2;
  // console.log("payloadrunyojn", payload);
  try {
    const resp = await yojn.complete(RUN_YOJN, payload);
    // console.log("Result from yojn.complete:", resp);
    if (resp) {
      // console.log("res>>", resp);
      await dispatch(
        getChatHistory({
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            orgId: "diaratech",
            userId: userDetails?.email,
          }),
        })
      );
      // console.log("ress", resp);
      return resp;
    }
  } catch (error) {
    console.log("Error", error);
  }
});

const RunYojnSlice = createSlice({
  name: "messages",
  initialState: {
    messages: [
      {
        text: "How can i help you today?",
        isBot: true,
      },
    ],
    status: "idle",
    error: null,
  },
  reducers: {
    adduserPrompt: (state, { payload }) => {
      // console.log("userPrompt", payload);
      state.messages = [...state.messages, payload];
    },
    editMessagesByHistory: (state, { payload }) => {
      // console.log("editpayload", payload);
      state.messages = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRunyojn.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(getRunyojn.fulfilled, (state, { payload }) => {
        state.status = "Succeeded";
        console.log("resppp", payload);
        // console.log("Response||", payload);
        state.messages = [
          ...state.messages,
          {
            text: payload?.YojnResponse || payload,
            isBot: true,
            conversationId: payload?.conversationId,
          },
        ];
      })
      .addCase(getRunyojn.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      });
  },
});
export const { adduserPrompt, editMessagesByHistory } = RunYojnSlice.actions;
export default RunYojnSlice.reducer;
